<template>
  <v-container
    fluid
  >
    <v-row dense>
      <v-col>
        <v-card class="mb-2">
          <v-card-title>Training Videos</v-card-title>
          <support-solutions
            :folder="67000581153"
          />
        </v-card>
        <v-card class="mb-2">
          <v-card-title>Tutorial Videos</v-card-title>
          <support-solutions
            :folder="67000576721"
          />
        </v-card>
        <v-card>
          <v-card-title>Sample Reports</v-card-title>
          <v-list
            class="mt-0 pt-0"
            dense
          >
            <template
              v-for="report in reports"
            >
              <v-divider
                :key="`${report.uuid}-divider`"
              />
              <v-list-item
                :key="`${report.uuid}-item`"
              >
                <v-list-icon
                  class="me-2"
                >
                  <v-icon
                    small
                    color="red"
                  >
                    fal fa-file-pdf
                  </v-icon>
                </v-list-icon>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ report.name }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn
                    text
                    small
                    @click="downloadReport(report.uuid, report.name)"
                  >
                    Download
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-list>
        </v-card>
      </v-col>
      <v-col>
        <v-card class="mb-2">
          <v-card-title>Frequently Asked Questions</v-card-title>
          <support-solutions
            :folder="67000357518"
          />
          <support-solutions
            :folder="67000362643"
          />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  components: {
    SupportSolutions: () => import('@/components/SupportSolutions.vue'),
  },
  data () {
    return {
      reports: [
        {"name":"Blood", "uuid":"17bde949-0c44-4a11-a42a-275b64fbfffb"},
        {"name":"Culture 1", "uuid":"1ee3acd3-f32d-64b2-bb73-0290be86a6a3"},
        {"name":"Culture 2", "uuid":"1ee30e83-c66a-6356-b2f5-eb225a060459"},
        {"name":"Culture 3", "uuid":"1ee1ccd4-e74d-615a-a0be-0290be86a6a3"},
        {"name":"Ear", "uuid":"1ebf0aff-88b1-6b82-b458-0ecd055851f7"},
        {"name":"Eye", "uuid":"7902b76e-54c3-48d2-97fc-72e6a419b585"},
        {"name":"Fusion", "uuid":"96700ce2-502e-4f3e-9918-a4b7251c8e87"},
        {"name":"GI", "uuid":"1ee0ef85-35f1-6ffa-a083-0290be86a6a3"},
        {"name":"Nail 1", "uuid":"1edb30d7-45b5-6364-a524-0290be86a6a3"},
        {"name":"Nail 2", "uuid":"1ebd0782-ba1d-66ee-a9b9-0ecd055851f7"},
        {"name":"No Organisms Detected", "uuid":"1ece9269-cb9c-6dec-9f0d-0290be86a6a3"},
        {"name":"Respiratory 1", "uuid":"1ed51442-ee89-6100-821a-0290be86a6a3"},
        {"name":"Respiratory 2", "uuid":"1ed7bba7-69c9-696a-b8bd-0290be86a6a3"},
        {"name":"Respiratory 3", "uuid":"1ee69122-f58f-61b6-b397-0290be86a6a3"},
        {"name":"Skin Scraping 1", "uuid":"1ee2fd62-1653-6404-a057-0290be86a6a3"},
        {"name":"Skin Scraping 2", "uuid":"1ee2fd55-c12b-6e2e-9cb5-0290be86a6a3"},
        {"name":"Skin Scraping 3", "uuid":"1ee2fd59-c766-6c40-946f-0290be86a6a3"},
        {"name":"STI", "uuid":"1ee04a37-4572-6efa-b597-0290be86a6a3"},
        {"name":"UTI 1", "uuid":"1ed43f48-0eed-68ae-9601-0290be86a6a3"},
        {"name":"UTI 2", "uuid":"1ee047a9-1b4b-657c-bd96-0290be86a6a3"},
        {"name":"UTI 3", "uuid":"1ee69128-f7d8-6386-be26-0290be86a6a3"},
        {"name":"Woman's Health 1", "uuid":"1ee0493f-e2ab-61f2-a109-0290be86a6a3"},
        {"name":"Woman's Health 2", "uuid":"1edcb627-c984-6fe2-8abc-0290be86a6a3"},
        {"name":"Wound - Osteomyelitis", "uuid":"1edf0464-a596-62a6-b7b7-0290be86a6a3"},
        {"name":"Wound 1", "uuid":"1ed40511-8c56-67c6-9a86-0290be86a6a3"},
        {"name":"Wound 2", "uuid":"1ed4050f-175b-6a54-b835-0290be86a6a3"},
      ],
    }
  },
  methods: {
    downloadReport (uuid, name) {
      this.loading = true
      this.axios.get(`/lab_results/${uuid}/interpretation/pdf`, {
        params: {
          redacted: true,
          client: this.$auth.user().client.uuid,
          output: 'base64',
          matrix: false,
        },
      })
        .then((response) => {
          const link = document.createElement('a')
          link.href = 'data:application/pdf;base64,' + response.data.report_content
          link.setAttribute('download', `${this.$auth.user().client.name} - ${name} - Arkstone.pdf`)
          document.body.appendChild(link)
          link.click()
          link.remove()
          this.dialog = false
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.errors.push('Lab result not found')
          } else {
            this.$toast.error(error.response.data.message)
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
